import * as types from '../types';

const setIsMfaNext = payload => ({
    type: types.SET_HAS_MFA,
    payload,
});

const setIsAuth = payload => ({
    type: types.SET_IS_AUTH,
    payload,
});

const endLoader = () => ({
    type: types.END_LOADER,
});

const initLoader = () => ({
    type: types.INIT_LOADER,
});

const requestError = payload => ({
    type: types.REQUEST_ERROR,
    payload,
});

const setResetPasswordSuccess = () => ({
    type: types.RESTE_PASSWORD_SUCCESS,
});
export { setIsMfaNext, setIsAuth, initLoader, endLoader, requestError, setResetPasswordSuccess };
