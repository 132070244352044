import React, { useState } from 'react';

import { TextField, Button, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import styles from './style.module.css';
import cx from 'classnames';
import { CircularProgress } from '@material-ui/core';

// import { CheckCircle } from '@styled-icons/boxicons-regular';
// import { Warning } from '@styled-icons/fluentui-system-filled';

import { isValidPassword, modes, passwordRules } from '../utils';

const Form = ({ onSubmit, handleSwitchMode, isLoading }) => {
    const [showPassword, setShowPassword] = useState(false);

    // const [showPasswordValidation, setShowPasswordValidation] = useState(false);

    const [state, updateState] = useState({
        username: '',
        password: '',
    });

    // const [errors, updateErrors] = useState({ password: [] });

    const handleOnChange = ({ target: { id, value } }) => {
        // if (id === 'password' && errors.password.length) {
        //     updateErrors(prevState => ({
        //         ...prevState,
        //         password: [],
        //     }));
        // }
        updateState(prevState => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleOnSubmit = e => {
        e.preventDefault();
        // const errors = isValidPassword(state.password);
        // if (errors.length) {
        //     return updateErrors(prevState => ({
        //         ...prevState,
        //         password: errors,
        //     }));
        // }
        onSubmit(state);
    };

    const handleForgotPassword = () => {
        handleSwitchMode(modes.FORGOT_PASSWORD);
    };

    const handleShowPassword = () => setShowPassword(!showPassword);

    return (
        <form className={styles.form} onSubmit={handleOnSubmit}>
            <TextField
                id="username"
                onChange={handleOnChange}
                value={state.username}
                placeholder="Username"
                variant="outlined"
                margin="dense"
                fullWidth
                InputProps={{
                    type: 'text',
                    required: true,
                }}
            />
            <div className={styles.passwordWrapper}>
                <TextField
                    key="password"
                    required
                    value={state.password}
                    variant="outlined"
                    fullWidth
                    placeholder="Password"
                    margin="dense"
                    onChange={handleOnChange}
                    // onFocus={() => setShowPasswordValidation(true)}
                    // onBlur={() => setShowPasswordValidation(false)}
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton size="small" aria-label="toggle password visibility" onClick={handleShowPassword}>
                                    {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {/* <div className={cx(styles.passwordRules, { [styles.show]: showPasswordValidation || errors.password.length })}>
                    {passwordRules.map(({ checkRule, rule }) => (
                        <div className={styles.rule}>
                            <div>{!checkRule(state.password) ? <CheckCircle size={14} color="green" /> : <Warning size={14} color="red" />}</div>
                            <div>{rule}</div>
                        </div>
                    ))}
                </div> */}
            </div>

            <span onClick={handleForgotPassword} className={styles.forgetPassword}>
                FORGOT PASSWORD?
            </span>
            <Button endIcon={isLoading && <CircularProgress size={16} className={styles.loadingIcon} />} className={styles.submitButton} disabled={isLoading} type="submit">
                login
            </Button>
        </form>
    );
};

export default Form;
