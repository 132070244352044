import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import styles from './style.module.css';
import { CircularProgress } from '@material-ui/core';

import { modes } from '../utils';

const ForgotPassword = ({ onSubmit, handleSwitchMode, isLoading }) => {
    const [state, setState] = useState({ email: '' });
    const [isSentEmailSuccess, setIsSentEmailSuccess] = useState(false);

    const onChange = ({ target: { value, id } }) => {
        setState(prevState => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSubmit = async e => {
        try {
            e.preventDefault();
            await onSubmit(state);
            setIsSentEmailSuccess(true);
        } catch (err) {}
    };

    const handleBack = () => {
        handleSwitchMode(modes.USERPASS);
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.title}>
                <span>Forgot Password</span>
                <span onClick={handleBack} className={styles.backToLogin}>
                    Back to Login
                </span>
            </div>
            {isSentEmailSuccess ? (
                <div className={styles.forgotPasswordSuccessText}>
                    An email has been sent to the provided E-mail address. Make sure you check your Spam email folder just in case your email service provider has redirected it
                    there.
                </div>
            ) : (
                <>
                    <TextField value={state.email} fullWidth required type="email" variant="outlined" onChange={onChange} margin="dense" placeholder="Email" id="email" />
                    <Button endIcon={isLoading && <CircularProgress size={16} className={styles.loadingIcon} />} type="submit" className={styles.submitButton}>
                        send
                    </Button>
                </>
            )}
        </form>
    );
};

export default ForgotPassword;
