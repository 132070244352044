import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import styles from './style.module.css';
import { CircularProgress } from '@material-ui/core';

const Mfa = ({ onSubmit, isLoading }) => {
    const [state, setState] = useState({ mfa: '' });

    const onChange = ({ target: { value, id } }) => {
        setState(prevState => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(state);
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <span>Your MFA Code</span>
            <TextField required onChange={onChange} value={state.mfa} id="mfa" variant="outlined" margin="dense" placeholder="MFA" />
            <Button endIcon={isLoading && <CircularProgress size={16} className={styles.loadingIcon} />} type="submit" className={styles.submitButton}>
                submit
            </Button>
        </form>
    );
};

export default Mfa;
