import { useState, useEffect } from 'react';

export default (param, defaultValue) => {
    // const [state, setState] = useState(defaultValue);

    // useEffect(() => {
    //     setState(new URLSearchParams(window.location.search).get(param) || defaultValue)
    // }, [param])

    return new URLSearchParams(window.location.search).get(param) || defaultValue;
};
