import React from 'react';
import Authenticator from './authenticator';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import theme from './theme';
import './App.css';
import './fonts.css';

export default () => (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <div className="App">
                <ToastContainer />
                <Authenticator />
            </div>
        </ThemeProvider>
    </Provider>
);
