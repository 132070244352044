const passwordRules = [
    {
        checkRule: password => /[A-Z]/.test(password),
        rule: 'password must contain at least one capital letter',
    },
    {
        checkRule: password => password.length >= 10,
        rule: 'password must be at least 10 characters long',
    },
    {
        checkRule: password => /[a-z]/.test(password),
        rule: 'password must contain at least one lowercase letter',
    },
    {
        checkRule: password => /[0-9]/.test(password),
        rule: 'password must contain at least one number',
    },
    {
        checkRule: password => /[!@#\$%\^\&*\)\(+=._-]/.test(password),
        rule: 'password must contain at least one special character',
    },
    // {
    //     checkRule: password => isConsecutive3Letters(password),
    //     rule: 'password can not contain consecutive, descending or 3 same characters',
    // },
];

const isValidPassword = (password = '') =>
    passwordRules.reduceRight((acc, { checkRule, rule }) => {
        if (!checkRule(password)) {
            acc.push(rule);
        }
        return acc;
    }, []);

function isConsecutive3Letters(password = '') {
    if (password.length < 3) return false;
    const str = password.toLowerCase();
    let isContain = false;
    for (let i = 0; i < str.length - 2; i++) {
        const check = str.substring(i, 3 + i).split('');
        isContain = check.every((c, idx) => (idx === check.length - 1 ? true : c.charCodeAt(0) === check[idx + 1].charCodeAt(0) + 1));
        if (isContain) break;
        isContain = check.reverse().every((c, idx) => (idx === check.length - 1 ? true : c.charCodeAt(0) === check[idx + 1].charCodeAt(0) + 1));
        if (isContain) break;
        isContain = check[0] === check[1] && check[1] === check[2];
        if (isContain) break;
    }

    return isContain;
}

export { isValidPassword, passwordRules };
