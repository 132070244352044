import { createTheme } from '@material-ui/core';

const COLORS = {
    DEFAULT: '#14172c',
    BACKGORUND: '#282b3f',
    PAPER: '#383851',
};

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 1280,
            md: 1366,
            lg: 1600,
            xl: 1920,
        },
    },
    transparent: '#00000000',
    palette: {
        primary: { main: '#fe0072' },
        secondary: { main: '#fe0072' },
        custom: {
            rainbow: ['#fe0072', '#c92477', '#ba2d93', '#b826db', '#7d2aa0', '#6b1d95', '#4a1f74'],
            rainbow2: ['#f50e77', '#e156dc', '#b21cea', '#bd85ff', '#823cf6'],
            text: {
                primary: '#fe0072',
                secondary: 'white',
                disabled: 'rgba(255, 255, 255, 0.43)',
            },
        },
        type: 'dark',
        background: {
            default: COLORS.DEFAULT,
            paper: COLORS.BACKGORUND,
            component: COLORS.PAPER,
        },
        chart: {
            a: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, '#9250d5'],
                    [1, '#fe0072'],
                ],
            },
            b: '#5545a0',
            c: '#fe0072',
        },
        heatMap: {
            minColor: '#ffd600',
            maxColor: '#ff4e4e',
        },
    },
    typography: {
        table: {
            display: 'inline-block',
        },
        body1: {},
        body2: {},
        fontFamily: ['Avenir'],
        fontWeight: 500,
        color: '#f1f2f6',
        fontSize: 16 * 1.4, //compensation for reduced base size
    },
    overrides: {
        MuiButton: {
            root: {
                background: '#fe0072',
                '&:hover': {
                    background: '#fe0072 !important',
                    filter: 'brightness(75%)',
                },
            },
        },
        MuiMenuItem: {
            root: {
                '&:hover': {
                    backgroundColor: '#14172c !important',
                    color: '#fe0072',
                },
                fontSize: '1.4rem',
            },
        },
        MuiSelect: {
            root: {
                fontSize: '1.4rem',
            },
        },
        MuiTabs: {
            root: {
                backgroundColor: COLORS.DEFAULT,
            },
            indicator: {
                backgroundColor: COLORS.BACKGORUND,
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '1.4rem',
            },
        },
        MuiSwitch: {
            colorSecondary: {
                '&$checked + $track': {
                    backgroundColor: '#fe0072',
                    opacity: 1,
                },
            },
        },
        MuiTab: {
            root: {
                '&$selected': {
                    color: 'white',
                    backgroundColor: COLORS.BACKGORUND,
                },
            },
            textColorInherit: {
                color: '#fe0072',
                opacity: 1,
            },
            wrapper: {
                marginBottom: 0,
            },
            labelIcon: {
                marginBottom: 0,
            },
        },

        AccountsTable: {
            MuiTableRow: { root: { padding: 0 } },
        },
        MuiCssBaseline: {
            body: {
                // fontSize: 16 * 1.4, //compensation for reduced base size
            },
        },
    },
});

export default theme;
