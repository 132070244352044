import config from '../../../config';
import CheqUI from '@combotag/cheq-ui';
import useGetParams from '../../../hooks/use-get-params';

const { Base } = CheqUI.ViewModels.Models;

export default ({ httpRequest }) =>
    ({ model }) =>
        model(
            class extends Base {
                constructor(name, baseRoute = name) {
                    super('profile', { baseRoute, baseURL: config.baseAPI });

                    const meta = getStoredJSON('meta');

                    this.state = {
                        resetToken: new URLSearchParams(window.location.search).get('hash'),
                        cancelResetToken: new URLSearchParams(window.location.search).get('cancel_reset_password'),
                        ready: false,
                        csrf: localStorage.getItem('csrf'),
                        authenticated: localStorage.getItem('authenticated') === 'true',
                        requiredAuths: {},
                        settings: meta.settings || {},
                        content: getStoredJSON('content'),
                        meta,
                        isLoginLoading: false,
                        remainingLoginAttempts: null,
                        passwordExpireIn: null,
                    };
                }

                hasContent() {
                    return !!Object.keys(this.state.content).length;
                }

                setupEffect(...auths) {
                    return () => {
                        this.requireAuths(...auths);
                        this.isAuthenticated();
                    };
                }

                requireAuths(...auths) {
                    return this.update(state => ({
                        ...state,
                        requiredAuths: auths.reduce(
                            (acc, auth) => ({
                                ...acc,
                                [auth]: true,
                            }),
                            {}
                        ),
                    }));
                }

                isAuthenticated() {
                    const { requiredAuths = {} } = this.state;

                    return Promise.all([
                        requiredAuths.userpass
                            ? this.http
                                  .post(`/${this.baseRoute}/is_authenticated`, {
                                      returnUrl: useGetParams('returnUrl', ''),
                                  })
                                  .then(({ data }) => data.authenticated)
                            : true,
                        requiredAuths.mfa ? this.http.post(`/${this.baseRoute}/is_mfa_authenticated`).then(({ data }) => data.authenticated) : true,
                    ]).then(authenticators =>
                        this.update(state => ({
                            ...state,
                            ready: true,
                            authenticated: Object.keys(requiredAuths).length && authenticators.every(authenticator => authenticator),
                        }))
                    );
                }
            }
        ).from([
            httpRequest({
                name: 'getMeta',
                type: 'post',
                updateState: meta => state => ({ ...state, meta }),
                postRequest: storeJSON('meta'),
            }),
            httpRequest({
                name: 'logout',
                type: 'post',
                postRequest: () => {
                    document.cookie = false;
                    localStorage.clear();
                    window.location.reload();
                },
            }),
            httpRequest({
                name: 'login',
                type: 'post',
                preRequest: function () {
                    this.update({ isLoginLoading: true });
                },
                updateState: updateAuth('userpass', 'id', true),
                onError: function (e) {
                    const { response: { data: { message = 'invalid_login', remainingLoginAttempts, hash } = {} } = {} } = e;
                    this.errorFor(message);
                    this.update({
                        isLoginLoading: false,
                        remainingLoginAttempts,
                        resetToken: hash,
                    });
                },
                postRequest: function ({ csrfToken, ...meta }) {
                    csrfToken && localStorage.setItem('csrf', csrfToken);
                    meta && localStorage.setItem('meta', JSON.stringify(meta));
                    this.update({
                        isLoginLoading: false,
                        passwordExpireIn: meta.passwordExpireIn,
                    });
                },
            }),
            httpRequest({
                name: 'resetPasswordNow',
                type: 'post',
                postRequest: function ({ hash }) {
                    this.update({ isLoginLoading: false, resetToken: hash });
                },
            }),
            httpRequest({
                name: 'mfa',
                type: 'post',
                updateState: updateAuth('mfa', 'mfaValid'),
            }),
            httpRequest({
                name: 'getTheme',
                type: 'post',
                updateState: theme => state => ({ ...state, theme }),
            }),
            httpRequest({
                name: 'getContent',
                type: 'post',
                updateState: content => ({ content }),
                postRequest: storeJSON('content'),
            }),
            httpRequest({
                type: 'post',
                name: 'forgotPassword',
                preRequest: function () {
                    this.update({ isLoginLoading: true });
                },
                onError: function () {
                    this.errorFor('forgot_pass_error');
                    this.update({ isLoginLoading: false });
                },
                postRequest: function (res, { onSuccess }) {
                    this.update({ isLoginLoading: false });
                    if (onSuccess) {
                        this.infoFor('forgot_pass_success');
                        return onSuccess();
                    }
                },
            }),
            httpRequest({
                name: 'resetPassword',
                type: 'post',
                postRequest: function (response, { onSuccess }) {
                    this.update({ resetToken: null });
                    if (onSuccess) {
                        return onSuccess();
                    }
                },
                onError: function ({ response }) {
                    this.errorFor(response.data);
                    this.update({ isLoginLoading: false });
                },
            }),
            httpRequest({
                name: 'abortResetPassword',
                type: 'post',
                postRequest: function (response, { onSuccess }) {
                    this.update({ cancelResetToken: null });
                    if (onSuccess) {
                        return onSuccess();
                    }
                },
            }),
        ]);

const updateAuth = (reqAuth, key, appendMeta) => data => state => ({
    ...state,
    meta: appendMeta ? { ...state.meta, ...data } : state.meta,
    requiredAuths: { ...state.requiredAuths, [reqAuth]: !!data[key] },
});

const getStoredJSON = (key, defaultValue = {}) => {
    try {
        return JSON.parse(localStorage.getItem(key)) || defaultValue;
    } catch {
        return defaultValue;
    }
};

const storeJSON = key => value => localStorage.setItem(key, JSON.stringify(value));
