import React, { useCallback } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './style.module.css';

const types = ['success', 'info', 'warn', 'error'];

export default ({ message, type = 'info', ...props }) => {
    const schema = { position: 'bottom-left', autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined };

    return toast[types.find(t => t === type) || 'info'](message, schema);
};
