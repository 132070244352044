import React, { useState, useEffect } from 'react';

import { TextField, Button, IconButton, InputAdornment, FormHelperText } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import styles from './style.module.css';
import cx from 'classnames';
import { CircularProgress } from '@material-ui/core';

import { CheckCircle } from '@styled-icons/boxicons-regular';
import { Warning } from '@styled-icons/fluentui-system-filled';
import { useSelector } from 'react-redux';

import { isValidPassword, modes, passwordRules } from '../utils';

const ResetPassword = ({ onSubmit, handleSwitchMode, isLoading }) => {
    const { isResetPasswordSuccess } = useSelector(state => state);
    const [showVerifyPassword, setShowVerifyPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [showPasswordValidation, setShowPasswordValidation] = useState(false);

    const [state, updateState] = useState({
        verifyPassword: '',
        password: '',
        errors: { password: [], verification: false },
    });

    const handleOnChange = ({ target: { id, value } }) => {
        const errors = isValidPassword(value);
        if (errors.length) {
            setShowPasswordValidation(true);
        }
        updateState(prevState => ({
            ...prevState,
            [id]: value,
            errors: { password: errors, verification: state.verifyPassword && value !== state.verifyPassword ? true : false },
        }));
    };

    const handleOnChangePasswordVerfication = ({ target: { id, value } }) => {
        updateState(prevState => ({
            ...prevState,
            [id]: value,
            errors: { ...prevState.errors, verification: value !== state.password ? true : false },
        }));
    };

    const handleOnSubmit = async e => {
        e.preventDefault();
        const errors = isValidPassword(state.password);
        if (errors.length) {
            return updateState(prevState => ({
                ...prevState,
                errors: { ...prevState.errors, password: errors },
            }));
        } else if (!isPasswordVerified()) return;
        try {
            await onSubmit(state);
        } catch (err) {}
    };

    const handleOnBlur = () => !state.errors.password.length && setShowPasswordValidation(false);

    const handleShowPassword = () => setShowPassword(!showPassword);

    const handleShowVerifyPassword = () => setShowVerifyPassword(!showVerifyPassword);

    const isPasswordVerified = () => {
        if (!state.errors.password.length) {
            const isVerified = state.password === state.verifyPassword;
            if (isVerified) return true;
            updateState(prevState => ({
                ...prevState,
                errors: { ...prevState, verification: true },
            }));
        }
    };

    const handleBackToLogin = () => handleSwitchMode(modes.USERPASS);

    useEffect(() => {
        isResetPasswordSuccess && handleSwitchMode('userpass');
    }, [isResetPasswordSuccess]);

    return (
        <div className={styles.form}>
            <div className={styles.title}>
                <span>Reset Password</span>
                <span onClick={handleBackToLogin} className={styles.backToLogin}>
                    Back to Login
                </span>
            </div>
            <div className={styles.passwordWrapper}>
                <TextField
                    id="password"
                    onChange={handleOnChange}
                    value={state.password}
                    placeholder="Password"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onBlur={handleOnBlur}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton size="small" aria-label="toggle password visibility" onClick={handleShowPassword}>
                                    {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <div className={cx(styles.passwordRules, { [styles.show]: showPasswordValidation })}>
                    {passwordRules.map(({ checkRule, rule }) => (
                        <div key={rule} className={styles.rule}>
                            <div>{checkRule(state.password) ? <CheckCircle size={14} color="green" /> : <Warning size={14} color="red" />}</div>
                            <div>{rule}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.passwordWrapper}>
                <TextField
                    key="verifyPassword"
                    value={state.verifyPassword}
                    variant="outlined"
                    fullWidth
                    placeholder="Verify Password"
                    margin="dense"
                    onChange={handleOnChangePasswordVerfication}
                    error={state.errors.verification}
                    id="verifyPassword"
                    type={showVerifyPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton size="small" aria-label="toggle verify password visibility" onClick={handleShowVerifyPassword}>
                                    {showVerifyPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {state.errors.verification && <FormHelperText className={styles.helperText}>Password must mach</FormHelperText>}
            </div>
            <Button
                endIcon={isLoading && <CircularProgress size={16} className={styles.loadingIcon} />}
                onClick={handleOnSubmit}
                className={styles.submitButton}
                disabled={isLoading}
            >
                reset
            </Button>
        </div>
    );
};

export default ResetPassword;
