import axios from 'axios';
import config from '../../config';
import * as actions from '../actions';
import Toast from '../../services/notifications';

const { baseAPI } = config;

const http = axios.create({
    baseURL: baseAPI,
    withCredentials: true,
});

const calitalizeEveryWord = sentence => {
    const newSentence = sentence
        .split('_')
        .map(word => {
            return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');
    return newSentence;
};

const errorMessage = (message, attempts) => {
    const messages = {
        invalid_login: `${calitalizeEveryWord(message)} - Remaining Attempts ${attempts}`,
        user_is_blocked: `${calitalizeEveryWord(message)}`,
    };
    return messages[message];
};

const login = {
    userpass: function (body) {
        return async (dispatch, getStore) => {
            try {
                dispatch(actions.initLoader());
                const store = getStore();
                const {
                    params: { returnUrl = '' },
                } = store;

                const {
                    data: { csrfToken, ...meta },
                } = await http.post(`/profile/login`, { ...body, returnUrl });

                const { data: content } = await http.post('/profile/get_content');

                meta && localStorage.setItem('meta', JSON.stringify(meta));
                csrfToken && localStorage.setItem('csrf', csrfToken);
                content && localStorage.setItem('content', JSON.stringify(content));

                const isMfaNext = store.params.scopes.includes('mfa');
                if (isMfaNext) {
                    dispatch(actions.endLoader());
                    dispatch(actions.setIsMfaNext(isMfaNext));
                } else {
                    isAuthenticated(store);
                }
            } catch (err) {
                const { message, remainingLoginAttempts } = err.response.data;
                const msg = errorMessage(message || err.response.data, remainingLoginAttempts);
                dispatch(actions.requestError(msg));
                Toast({ type: 'error', message: msg });
            }
        };
    },
    mfa: body => async (dispatch, getStore) => {
        try {
            dispatch(actions.initLoader());
            const store = getStore();
            await http.post(`/profile/mfa`, { ...body });
            isAuthenticated(store);
        } catch (err) {
            const { message, remainingLoginAttempts } = err.response.data;
            const msg = errorMessage(message, remainingLoginAttempts);
            Toast({ type: 'error', message: msg });
            dispatch(actions.endLoader());
        }
    },
};

const isAuthenticated = async store => {
    const { scopes, returnUrl } = store.params;

    const checkAuthPromises = scopes.reduce((acc, scope) => {
        if (scope === 'userpass') {
            acc.push(async () => await http.post(`/profile/is_authenticated`, { returnUrl }));
        }
        if (scope === 'mfa') {
            acc.push(async () => await http.post(`/profile/is_mfa_authenticated`));
        }
        return acc;
    }, []);

    try {
        await Promise.allSettled(checkAuthPromises);
        window.location.href = returnUrl;
    } catch (err) {
        console.log({ err });
    }
};

const forgotPassword = body => async (dispatch, getStore) => {
    const store = getStore();
    const { returnUrl } = store.params;
    try {
        dispatch(actions.initLoader());
        await http.post(`/profile/forgot_password`, { ...body, returnUrl });
        return 'OK';
    } catch (err) {
        const { message } = err.response.data;
        const msg = errorMessage(message || err.response.data);
        Toast({ type: 'error', message: msg });
    } finally {
        dispatch(actions.endLoader());
    }
};

const resetPassword =
    ({ password }) =>
    async (dispatch, getStore) => {
        const store = getStore();
        dispatch(actions.initLoader());
        const { returnUrl, hash: resetToken } = store.params;
        try {
            await http.post(`/profile/reset_password`, { password, returnUrl, resetToken });
            Toast({ type: 'success', message: 'Reset Password Successfully' });
            dispatch(actions.setResetPasswordSuccess());
        } catch (err) {
            const { message } = err.response.data;
            const msg = errorMessage(message || err.response.data);
            Toast({ type: 'error', message: msg });
            Promise.reject();
        } finally {
            dispatch(actions.endLoader());
        }
    };

const logout = () => {};

export { login, logout, forgotPassword, resetPassword };
