import useGetParams from '../../hooks/use-get-params';
import * as types from '../types';

const intialState = {
    params: { scopes: useGetParams('scopes', 'userpass')?.split(','), returnUrl: useGetParams('returnUrl', 'https://ppc.cheq-platform.com/'), hash: useGetParams('hash', '') },
    isUserAuth: false,
    isLoading: false,
    isMfaNext: false,
    isResetPasswordSuccess: false,
    err: undefined,
};

const root = (state = intialState, action) => {
    switch (action.type) {
        case types.SET_IS_AUTH: {
            const { payload: isUserAuth } = action;
            return { ...state, isUserAuth };
        }
        case types.INIT_LOADER: {
            return { ...state, isLoading: true };
        }
        case types.END_LOADER: {
            return { ...state, isLoading: false };
        }
        case types.SET_HAS_MFA: {
            const { payload: isMfaNext } = action;
            return { ...state, isMfaNext };
        }
        case types.REQUEST_ERROR: {
            const { payload: err } = action;
            return { ...state, err, isLoading: false };
        }
        case types.RESTE_PASSWORD_SUCCESS: {
            return { ...state, isResetPasswordSuccess: true };
        }
        default:
            return state;
    }
};

export { root };
