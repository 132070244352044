import React from 'react';
import useGetParams from '../../hooks/use-get-params';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as CheqLogo } from './cheq.svg';
import { ReactComponent as PPCLogo } from './ppc.svg';
import { ReactComponent as NewLogo } from './new_logo.svg';
import logo from './Logo_Login.png';
import styles from './logo.module.css'

export default ({ height, width = 250, style, ...props }) => {
    const returnDomain = getReturnUrl(decodeURIComponent(useGetParams('returnUrl', '')));

    //const Logo = returnDomain.startsWith('ppc') || returnDomain.startsWith('clicktrue') ?  PPCLogo : CheqLogo;

    return (
        <div>
            <img src={logo} alt="logo" className={styles.logo}/>
            <div>{/* <CheqLogo /> */}</div>
        </div>
    );
};

const getReturnUrl = returnUrl => {
    let url;
    try {
        url = new URL(returnUrl);
    } catch (e) {
        url = new URL(document.referrer || 'https://cheq-platform.com');
    }
    return url.host;
};
