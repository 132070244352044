const defaultValues = {
  version: "0.0.1",
  baseAPI: process.env.REACT_APP_BASE_API,
  getENV: () => {
    return process.env.REACT_APP_ENV || "default";
  },
};

const getENV = defaultValues.getENV;

const ENVIRONMENT = {
  default: {
    ...defaultValues,
  },
  local: {
    ...defaultValues,
    baseAPI: "http://local-api.cheq-platform.com",
  },
  development: {
    ...defaultValues,
    baseAPI: "https://api-development.cheq-platform.com",
  },
  staging: {
    ...defaultValues,
    baseAPI: "https://api-staging.cheq-platform.com",
  },
  preproduction: {
    ...defaultValues,
    baseAPI: "https://api-preproduction.cheq-platform.com",
  },
};

export default ENVIRONMENT[getENV()];
