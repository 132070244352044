import React, { useEffect, useState } from 'react';

import Logo from './logo';
import { ReactComponent as CheqCloud } from '../assets/cheq_cloud.svg';

import { useSelector, useDispatch } from 'react-redux';
import * as thunks from '../redux/thunks';

import UserpassForm from './UserpassForm';
import Mfa from './Mfa';
import ForgotPassword from './ForgotPassword';

import { modes } from '../utils';

import styles from './style.module.css';
import ResetPassword from './ResetPassword';

const Main = () => {
    const dispatch = useDispatch();
    const isMfaNext = useSelector(state => state.isMfaNext);
    const isLoading = useSelector(state => state.isLoading);
    const { hash } = useSelector(state => state.params);

    const [selectedMode, updateSelectedMode] = useState({ current: modes.USERPASS, previous: '' });

    const userPassSubmit = state => {
        dispatch(thunks.login.userpass(state));
    };

    const mfaSubmit = state => {
        dispatch(thunks.login.mfa(state));
    };

    const forgotPasswordSubmit = state => {
        return dispatch(thunks.forgotPassword(state));
    };

    const resetPasswordSubmit = async state => {
        await dispatch(thunks.resetPassword(state));
    };

    const handleSwitchMode = current => {
        updateSelectedMode(({ current: previous }) => ({
            previous,
            current,
        }));
    };

    useEffect(() => {
        hash && handleSwitchMode(modes.RESET_PASSWORD);
    }, [hash]);

    useEffect(() => {
        isMfaNext && handleSwitchMode(modes.MFA);
    }, [isMfaNext]);

    const forms = {
        [modes.MFA]: <Mfa onSubmit={mfaSubmit} isLoading={isLoading} />,
        [modes.USERPASS]: <UserpassForm onSubmit={userPassSubmit} handleSwitchMode={handleSwitchMode} isLoading={isLoading} />,
        [modes.FORGOT_PASSWORD]: <ForgotPassword onSubmit={forgotPasswordSubmit} handleSwitchMode={handleSwitchMode} isLoading={isLoading} />,
        [modes.RESET_PASSWORD]: <ResetPassword onSubmit={resetPasswordSubmit} isLoading={isLoading} handleSwitchMode={handleSwitchMode} />,
    };

    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
                <div className={styles.formWrapper}>
                    <Logo />
                    {forms[selectedMode.current]}
                </div>
            </div>
            <div className={styles.welcomeContainer}>
                <div className={styles.welcome}>
                    <div className={styles.welcomeText}>
                        <span className={styles.welcomeTitle}>Welcome</span>
                        <span className={styles.welcomeDescription}>to your Go-to-Market Security platform</span>
                    </div>
                    <div className={styles.welcomeLogo}>
                        <CheqCloud />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
